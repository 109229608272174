<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16">
    <path class="chromeIcon" :fill="iconColor" fill-rule="evenodd" d="M39.3985,25 C39.828,25.906 40,26.9794999 40,28.0000002 C40,30.1875 39.2290001,32.0624999 37.6880002,33.6250001 C36.1459999,35.1875001 34.2815,35.979 32.0940002,36 L32.0940002,36 L35.375,30.344 C35.8544999,29.6355001 36.0939999,28.8545001 36.0939999,28.0000002 C36.0939999,26.8544999 35.5624999,25.8125 34.75,25 L34.75,25 Z M24.9999999,24.0000001 L28.2810001,29.7505001 C28.614,30.438 29.114,31.0005 29.781,31.438 C30.448,31.875 31.1874999,32.0939999 32.0000001,32.0939999 C32.5100002,32.0939999 32.8995001,31.9595 33.1330001,31.8909999 L33.1330001,31.8909999 L30.8124999,35.9065 C28.875,35.6145 27.2550001,34.7240001 25.9529999,33.2345001 C24.651,31.745 24,30 24,28.0000002 C24,26.6044999 24.3125,25.2295 24.9999999,24.0000001 L24.9999999,24.0000001 Z M32,25 C33.6568543,25 35,26.3431458 35,28 C35,29.6568543 33.6568543,31 32,31 C30.3431458,31 29,29.6568543 29,28 C29,26.3431458 30.3431458,25 32,25 Z M32.0000001,20 C33.4375001,20 34.7655,20.3545 35.9844999,21.0624999 C37.2030002,21.7709999 38.1665001,22.7920001 38.8750001,24.0000001 L38.8750001,24.0000001 L32.0000001,24.0000001 C31.0624999,24.0000001 30.2235,24.1930001 29.4845,24.7655 C28.7445,25.339 28.2290001,26.1045 28.0000001,26.9999999 L28.0000001,26.9999999 L25.6794999,23.0940001 C26.4139999,22.1014999 27.4165001,21.3279999 28.5000001,20.797 C29.583,20.2655 30.7499999,20 32.0000001,20 Z" transform="translate(-24 -20)"/>
  </svg>
</template>

<script>
export default {
  name: "ChromeIcon",
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: "#050505"
    }
  }
};
</script>
